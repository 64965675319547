.title-name {
    text-align: right;
    font-size: xx-large;
    margin-right: 36px;
    opacity: 0.3;
    animation-duration: 5s;
    animation-name: heartbeat;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes heartbeat {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.3;
    }
}
