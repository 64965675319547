@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

/* GRID */
.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}


.App-header {
    background-color: #282c34;
    display: flex;
    color: #282c34;
    transition: color 3s;
}

.App-header:hover {
    color: white;
}

.App-footer {
    background-color: #edd3a1;
    font-size: small;
    text-align: center;
}

.App-link {
    color: #61dafb;
}

/* GENERAL */
* {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    position: relative;
}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid lightgray;
    background: white;
}

/* Calendar */
.calendar {
    display: block;
    position: relative;
    width: 90%;
    background: white;
    border: 1px solid lightgray;
    height: auto;
    margin: 0 auto;
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid lightgray;
}

.calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
}

.calendar .header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: dodgerblue;
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: gray;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid lightgray;
}

.calendar .body .cell {
    position: relative;
    height: 6em;
    border-right: 1px solid lightgray;
    overflow: hidden;
    cursor: pointer;
    background: white;
    transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
    background: whitesmoke;
    transition: 0.5s ease-out;
}

.calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1affa0 0%, #cff153 40%);
    border-image-slice: 1;
}

.calendar .body .row {
    border-bottom: 1px solid lightgray;
}

.calendar .body .row:last-child {
    border-bottom: none;
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}

.calendar .body .cell:hover .number, .calendar .body .selected .number {
    visibility: hidden;
}

.calendar .body .disabled {
    color: lightgray;
    pointer-events: none;
}

.calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: #1affa0;
    opacity: 0;
    font-size: 5em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg {
    opacity: 0.2;
    transition: .5s ease-in;
}

.calendar .body .cell .title {
    font-size: 90%;
    margin-top: 1.6em;
    margin-left: 0.3em;
    margin-right: 0.3em;
}

.calendar .body .column {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}

.calendar .day-of-week {
    text-align: center;
}

.Home {
    text-align: center;
}

.Home-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Home-logo {
        animation: Home-logo-spin infinite 20s linear;
    }
}

.Home-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.Home-link {
    color: #61dafb;
}

@keyframes Home-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.title-name {
    text-align: right;
    font-size: xx-large;
    margin-right: 36px;
    opacity: 0.3;
    animation-duration: 5s;
    animation-name: heartbeat;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes heartbeat {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 0.3;
    }
}

.diaryIndex {display: flex;}
.dateBox {
    flex: 0 0 150px;
    margin: 10px;
    font-family: cursive;
    font-size: x-large;
    font-weight: bold;
}
.textBox {
    overflow: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 180px);
    margin: 10px;
    border-left: 1px solid gray;
    padding: 4px 4px;
}

