.App-header {
    background-color: #282c34;
    display: flex;
    color: #282c34;
    transition: color 3s;
}

.App-header:hover {
    color: white;
}

.App-footer {
    background-color: #edd3a1;
    font-size: small;
    text-align: center;
}

.App-link {
    color: #61dafb;
}
