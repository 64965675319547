.Home {
    text-align: center;
}

.Home-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Home-logo {
        animation: Home-logo-spin infinite 20s linear;
    }
}

.Home-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.Home-link {
    color: #61dafb;
}

@keyframes Home-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
