.diaryIndex {display: flex;}
.dateBox {
    flex: 0 0 150px;
    margin: 10px;
    font-family: cursive;
    font-size: x-large;
    font-weight: bold;
}
.textBox {
    overflow: auto;
    flex: 0 0 calc(100% - 180px);
    margin: 10px;
    border-left: 1px solid gray;
    padding: 4px 4px;
}
